import { useQuery } from 'react-query';
import { apiPaths } from '../../utils/apiPaths';
import { useStateContext } from '../../contexts/StateProvider';
import { GET, GET_PAGED } from '../../utils/api';
import { useMemo } from 'react';
import {
  Budget,
  CustomerViewerSettings,
  PagedItems,
  Role,
  RouteDetail,
} from '../../domain/types';
import { ConsumptionReport } from '../Dashboard/Consumption/ConsumptionReport';
import { CommitTrackerReport } from '../Dashboard/CommitTracker/CommitTrackerReport';
import { BudgetBurndownReport } from '../Dashboard/BudgetBurndown/BudgetBurndownReport';
import { PurchaseOrderBurndownReport } from '../Dashboard/PurchaseOrderBurndown/PurchaseOrderBurndownReport';
import { CustomersCommitTrackerReport } from '../Dashboard/CustomersCommitTracker/CustomersCommitTracker';
import { createRoutePaths } from '../../utils/createRoutePaths';
import { useAuthContext } from '../../contexts/AuthProvider';
import { VOID_ID } from '../../domain/constants';
import { RecommenderReport } from '../Dashboard/Recommender/RecommenderReport';
import { BigQueryUsageReport } from '../Dashboard/BigQueryUsage/BigQueryUsageReport';

export const dashboardPaths = createRoutePaths(
  {
    consumption: '/consumption',
    commitTracker: '/commit-tracker',
    budgetBurndown: '/budget-burndown',
    purchaseOrder: '/purchase-orders-burndown',
    customersCommitTracker: '/customers-commit-tracker',
    recommender: '/recommender',
    bigQueryUsage: '/bigquery-usage',
  },
  '/app/dashboard'
);

export const dashboardRoutes: RouteDetail[] = [
  {
    label: 'Consumption',
    path: dashboardPaths.consumption,
    Component: ConsumptionReport,
  },
  {
    label: 'BigQuery Analytics',
    path: dashboardPaths.bigQueryUsage,
    Component: BigQueryUsageReport,
  },
  {
    label: 'Budget Burndown',
    path: dashboardPaths.budgetBurndown,
    Component: BudgetBurndownReport,
  },
  {
    label: 'AB Commit Tracker',
    path: dashboardPaths.customersCommitTracker,
    Component: CustomersCommitTrackerReport,
    roles: [Role.SYSTEM_ADMIN, Role.INTERNAL_VIEWER],
  },
  {
    label: 'Commit Tracker',
    path: dashboardPaths.commitTracker,
    Component: CommitTrackerReport,
  },
  {
    label: 'PO Burndown',
    path: dashboardPaths.purchaseOrder,
    Component: PurchaseOrderBurndownReport,
  },
  {
    label: 'Recommender',
    path: dashboardPaths.recommender,
    Component: RecommenderReport,
    roles: [Role.SYSTEM_ADMIN, Role.CUSTOMER_ADMIN],
  },
];

const usePOBurndownAvailable = (customerId: number) => {
  const customerPOmap = useMemo(() => {
    return new Map<number, boolean>();
  }, []);

  const apiPath = apiPaths.purchaseOrder(customerId);
  const fetchPO: boolean = !!customerId && !customerPOmap.get(customerId);
  const { data } = useQuery(apiPath.key.list(), () => GET_PAGED(apiPath.base), {
    enabled: fetchPO,
  });

  if (customerId && data) {
    customerPOmap.set(customerId, true);
  }

  return useMemo(() => data?.items.length > 0, [data?.items]);
};

const useBudgetBurndownPermission = (customerId: number) => {
  const { userRole } = useAuthContext();
  const isQueryEnabled = userRole === Role.CUSTOMER_VIEWER;

  const apiPath = apiPaths.customerViewerSettings;
  const { data } = useQuery<PagedItems<CustomerViewerSettings>>(
    apiPaths.users.key.list(),
    () => GET(apiPath.base),
    {
      enabled: isQueryEnabled,
    }
  );
  const budgetPath: string = apiPaths.budget(customerId).active;
  const { data: activeBudget } = useQuery<Budget>(
    budgetPath,
    () => GET(budgetPath),
    {
      enabled: isQueryEnabled,
    }
  );

  return useMemo(() => {
    const hasCustomerViewerRestrictions = !!data?.items.length;
    const hasActiveBudget = !!activeBudget;

    return {
      hasCustomerViewerRestrictions,
      hasActiveBudget,
    };
  }, [data, activeBudget]);
};

export const useDashboardRoutes = () => {
  const { selectedCustomerId: customerId } = useStateContext();
  const poBurndownAvailable = usePOBurndownAvailable(customerId);
  const { userRole } = useAuthContext();

  const {
    hasActiveBudget,
    hasCustomerViewerRestrictions,
  } = useBudgetBurndownPermission(customerId);

  const routes = useMemo(() => {
    const routesSettings = [
      {
        route: dashboardPaths.consumption,
        disabled: false,
      },
      {
        route: dashboardPaths.budgetBurndown,
        disabled:
          customerId === VOID_ID ||
          (!hasActiveBudget && userRole === Role.CUSTOMER_VIEWER),
      },
      {
        route: dashboardPaths.bigQueryUsage,
        disabled:
          customerId === VOID_ID ||
          userRole === Role.CUSTOMER_VIEWER ||
          userRole === Role.CUSTOMER_ADMIN,
      },
      {
        route: dashboardPaths.commitTracker,
        disabled:
          customerId === VOID_ID ||
          (hasCustomerViewerRestrictions && userRole === Role.CUSTOMER_VIEWER),
      },
      {
        route: dashboardPaths.customersCommitTracker,
        disabled: false,
      },
      {
        route: dashboardPaths.purchaseOrder,
        disabled:
          customerId === VOID_ID ||
          !poBurndownAvailable ||
          hasCustomerViewerRestrictions,
      },
      {
        route: dashboardPaths.recommender,
        disabled: false,
      },
    ];

    return dashboardRoutes.filter((route) => {
      const disabled =
        routesSettings.find((routeSetting) => routeSetting.route === route.path)
          ?.disabled ?? false;
      return !disabled;
    });
  }, [
    poBurndownAvailable,
    customerId,
    hasActiveBudget,
    hasCustomerViewerRestrictions,
    userRole,
  ]);

  return routes;
};
